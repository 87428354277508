import React, { useEffect } from "react"
import { AnimatedSection, AnimatedLink } from "@components/shared"
import { withHtml } from "@components/logic"

import {
  wrapp,
  category,
  category__wrapper,
  category__img,
} from "../styles/categoryitem.module.scss"

const Title = withHtml("h3")
const Description = withHtml("article")

interface ICategoryItem {
  name: string
  slug: string
  description: string
}

const CategoryItem: React.FC<ICategoryItem> = ({
  name,
  slug,
  description,
}): JSX.Element => {
  let lang = localStorage.getItem("language")
  return (
    <AnimatedLink to={`${lang}/blog/categories/${slug}/`} className={category__wrapper}>
    <div className={category}>
      <AnimatedSection className={wrapp}>
        <Title>{name}</Title>
        <Description>{description}</Description>
      </AnimatedSection>
    </div>
  </AnimatedLink>
  )
}

export default CategoryItem
